import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import VectorVirtualAgent from "./components/VectorVirtualAgent/VectorVirtualAgent";
import Header from "./components/Common/Header";
import Footer from "./components/Common/Footer";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./components/VectorVirtualAgent/Landing";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { createTheme } from "@material-ui/core/styles";
const rootElement = document.getElementById("root");

const theme = createTheme({
    overrides: {
        typography: {
            allVariants: {
                fontFamily: "Poppins",
                textTransform: "none",
            },
        },
    },
});
ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <BrowserRouter>
            <div className="wrapper">
                <Header />
                <Routes>
                    <Route path="/" element={<VectorVirtualAgent />}>
                        <Route path="/landing" element={<Landing />} />
                    </Route>
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    </MuiThemeProvider>,
    rootElement
);
