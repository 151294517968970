import { React } from "react";
import { Outlet } from "react-router-dom";
import "./styles.css";

export default function VectorVirtualAgent() {
    return (
        <div id="main-content">
            <Outlet />
        </div>
    );
}
