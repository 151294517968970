import React from "react";
import Grid from "@mui/material/Grid";
// CSS
import "./Footer.css";

export default function Footer() {
    return (
        <>
            <Grid className="footer" container spacing={0} sx={{ background: "#1771ff", color: "#FFF", marginBottom: "0px", paddingTop: "11px", position: "fixed", bottom: "0px" }}>
                <Grid item xs={6}>
                    SpeedyCash
                </Grid>
                <Grid item xs={6} className="right">
                    &copy;2023
                </Grid>
            </Grid>
        </>
    );
}
