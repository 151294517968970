import { React, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Divider } from "material-ui";

export default function DueDateChanged(gotoScreen = "", customerFields, newDate) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <h4>Due Date Changed</h4>
            <div className="headerRow">Your due date was updated</div>
            <br />
            <Divider />
            <br />
            <Grid item>Previous Due Date</Grid>
            <Grid item justifyContent="flex-end">
                <b>{formatDate(customerFields.loan_due_date)}</b>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid item>Updated Due Date</Grid>
            <Grid item justifyContent="flex-end">
                <b>{formatDate(newDate)}</b>
            </Grid>
            <br />
            <br />
        </>
    );
}
