import { React, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Divider } from "material-ui";

export default function CheckLoanBalance(gotoScreen = "", customerFields = {}) {
    const [view, setView] = useState();
    const handleChange = (event, nextView) => {
        setView(nextView);
    };

    const saveChanges = (e) => {
        console.log("PAID");
        gotoScreen("dueDateChanged");
    };

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <>
            <h4>Your Loan Balance</h4>
            <div className="headerRow">See below for details.</div>
            <br />
            <Divider />
            <br />
            <Grid item>Total Balance Due</Grid>
            <Grid item justifyContent="flex-end">
                <b>{formatter.format(customerFields.loan_balance)}</b>
            </Grid>
        </>
    );
}
