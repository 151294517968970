import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
let objUser;

function Header() {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const location = useLocation();

    if (!isLoading && !isAuthenticated) {
        loginWithRedirect({ appState: { targetUrl: "/auth" } });
    } else {
        if (isAuthenticated && !isLoading) {
            objUser = JSON.parse(sessionStorage.getItem("user"));
        }
    }

    console.log(location);

    return <></>;
}

export default Header;
