import { React, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Divider } from "material-ui";

export default function DocumentStatus(gotoScreen = "") {
    const [view, setView] = useState();
    const handleChange = (event, nextView) => {
        setView(nextView);
    };

    const saveChanges = (e) => {
        console.log("PAID");
        gotoScreen("dueDateChanged");
    };

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <>
            <h4>Document Status</h4>
            <div className="headerRow">See below for details.</div>
            <br />
            <Divider />
            <br />
            <h6>To check on the status of your pending documents, click to call the number below and you will be connected with a customer service specialist.</h6>
            <br />
            <Divider />
            <br />
            <Grid item>Call Customer Service at</Grid>
            <Grid item justifyContent="flex-end">
                <b>
                    <a href="tel:8884742133" target="_parent">
                        888-474-2133
                    </a>
                </b>
            </Grid>
        </>
    );
}
