import { React, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Divider } from "material-ui";

export default function PaymentReceived(gotoScreen = "", customerFields = {}, pmt) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return (
        <>
            <h4>Payment Received</h4>
            <div className="headerRow">Thank you for submitting your payment.</div>
            <br />
            <Divider />
            <br />
            <Grid item>Payment Amount</Grid>
            <Grid item justifyContent="flex-end">
                <b>{formatter.format(pmt)}</b>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid item>Processing Date</Grid>
            <Grid item justifyContent="flex-end">
                <b>Today</b>
            </Grid>

            <br />
        </>
    );
}
