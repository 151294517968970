import { React, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Divider } from "material-ui";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export default function ChangeDueDate(gotoScreen = "", customerFields = {}, updateCustomerField, dateRange, newDueDate, setNewDueDate) {
    let d = "";
    const saveChanges = (e) => {
        console.log(e);
        console.log("PAID");
        d = e.$y + "-" + eval(e.$M + 1) + "-" + e.$D;
        updateCustomerField("chosen_due_date", d);
    };

    const go = () => {
        if (d === "") {
            alert("Date is required");
        } else {
            gotoScreen("dueDateChanged", d);
        }
    };

    const handleChange = () => {};

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <h4>Change Due Date</h4>
            <div className="headerRow">Please select a new due date below. You may adjust the date by up to {customerFields.max_date_change_days} days.</div>
            <br />
            <Divider />
            <br />
            <Grid item>Current Due Date</Grid>
            <Grid item justifyContent="flex-end">
                <b>{formatDate(customerFields.loan_due_date)}</b>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid item>New Due Date</Grid>
            <br />
            <Grid item justifyContent="flex-end">
                <FormControl fullWidth>
                    {dateRange && dateRange.length > 0 ? (
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={newDueDate} label="Age" onChange={handleChange} size="small">
                            {dateRange.map((date, i) => (
                                <MenuItem key={date.date} value={date.date}>
                                    {date.label}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <></>
                    )}
                </FormControl>
            </Grid>
            <br />
            <br />
            <Button variant="contained" disableElevation fullWidth onClick={(e) => go(e)}>
                Confirm Changes
            </Button>

            <br />
        </>
    );
}
